<template>
<div v-loading="isLoading" class="mainCon">
  <!-- 订单列表 -->
  <div class="myOrder" v-if="OrderList.length && !isLoading">
    <div class="tableHeader displayRow">
      <span style="flex:1;textalign: center">订单详情</span>
      <span style="width: 115px; textAlign: center">金额</span>
      <span style="width: 115px; textAlign: center">收货人</span>
      <span style="width: 115px; textAlign: center">合计</span>
    </div>
    <ul 
    v-loading="downLoading"   
    element-loading-text="正在下载中,请耐心等待"
    element-loading-background="rgba(250,250,250,.8)">
      <li v-for="(item, index) in OrderList" :key="index" class="orderItem">
        <div class="top displayRow">
          <div>  <span class="optTime">{{ item.createTime }}</span>
          <span>订单号:</span><span class="SID">{{ item.orderNo }}</span></div>
          <div>
             <el-button  plain type="primary" size="mini" @click="downList(item)">下载清单</el-button>
          </div>
        
        </div>
        <el-table
          :data="item.orderItemList"
          :span-method="objectSpanMethod"
          border
          :show-header="false"
          style="width: 100%;font-size:12px"
        >
          <el-table-column>
            <template slot-scope="scope">
              <div class="displayRow">
                <img :src="scope.row.skuImageUrl" alt="" class="img" @click="toDetails(scope.row.goodsId)"/>
                <p class="disc">{{ scope.row.goodsTitle }}{{scope.row.skuName}}</p>
                <p class="graySty fontSm">×{{ scope.row.skuNum }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="skuPrice" align="center" width="115">
          </el-table-column>
          <el-table-column align="center" width="115">
            <template>
              <el-popover
                  placement="left"
                  width="200"
                  trigger="hover">
                  <div class="popoverSty">
                    <div class="fontSm fontBold">{{item.realName}}</div>
                    <div class="fontSm">{{item.userAddress}}</div>
                    <div class="fontSm">{{item.userPhone}}</div>
                  </div>
                  <span slot="reference">{{ item.realName }}</span>
                </el-popover>
              <!-- <span>{{ item.consignee }}</span> -->
            </template>
          </el-table-column>
          <el-table-column align="center" width="115">
            <template>
              <span>{{ account(index) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </ul>

     <el-pagination
         v-if="pageFlag"
          background
          layout="prev, pager, next"
          :page-size="10"
          :total="totalCount"
          :current-page="currentPage"
          @current-change="changePage"
          class="textCenter"
        >
        </el-pagination>
  </div>

<div v-if="OrderList.length === 0 && !isLoading" class="textCenter">
    <el-image
      :src="url"
      fit="fit"></el-image>
    <p class="graySty noDataText">暂无订单信息，赶紧去 <span class="themeColor pointer" @click="toHome">商城选购 </span>吧！</p>
</div>
</div>
</template>

<script>
import {getOrderList,exportOrder} from '@/assets/js/api'
export default {
  name: "Dashboard",
  data() {
    return {
      userInfo:{},
      isLoading:true,
      url:require('@/assets/image/noData.png'),
      OrderList: [],
      pageFlag:true,
      totalCount: 0,
      currentPage: 1,
      downLoading:false,
    };
  },
  methods: {
      //回到详情页
      toDetails(classifyId){
        this.$router.push({
          path:'/DetailsData',
          query:{
            classifyId:classifyId
          }
        })
      },
     // 下载购物车清单
      downList(item){
        this.downLoading = true;
        let data = item;
        exportOrder(data).then(res =>{
             //创建一个隐藏的a连接，
    const link = document.createElement('a');
    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
    link.style.display = 'none';
    //设置连接
    link.href = URL.createObjectURL(blob);
    link.download = `销售清单-${item.orderNo}`; 
    document.body.appendChild(link);
    //模拟点击事件
    link.click();
        }).finally(() =>{
          this.downLoading = false;
        })
      // }
      },
    // 选择省市区街道
    regionChange(data) {
      console.log(data);
    },

    // 表格合并列
    objectSpanMethod({ row, column, rowIndex, columnIndex }) { 
      if (rowIndex === 0) {
        if (columnIndex === 2 || columnIndex === 3) {
          return [2, 1];
        }
      }
    },
    toHome(){
      this.$router.push('/homeData')
    },
    //获取我的订单
    getData(){
      this.isLoading = true;
       this.pageFlag = true;
       let data = {
             pageNo: this.currentPage,
            pageSize:50
       }
        getOrderList(data,this.userInfo.token).then(res =>{
          if(res.data.code === 200){
            this.OrderList = res.data.result.records;
            this.totalCount = res.data.result.total;
          }
        }).finally(() =>{
          this.isLoading = false;
        })
    },
       changePage(currentPage) {
      this.currentPage = currentPage;
      this.getData();
    },
  },
  computed: {
    account() {
      return function (index) {
        let sum = 0;
        if(this.OrderList[index].orderItemList){
           sum = this.OrderList[index].orderItemList.reduce((acc, item) => {
          return acc + +item.totalPrice;
        }, 0);
        return sum;
        }
       
      };
    },
  },
  created(){
    this.userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')):{};
    this.getData();
  }
};
</script>

<style  scoped>
.mainCon{
   min-height: 450px;
}
.myOrder {
  width: 1200px;
  margin: 0 auto;
 
}
.orderItem {
  margin-bottom: 20px;
}
.tableHeader {
  padding: 10px;
  background-color: #eee;
  font-size: 12px;
  color: #666;
  margin-bottom: 15px;
}
.top {
  border-bottom: 1px solid #ccc;
  padding: 10px;
  background-color: #eee;
  font-size: 12px;
  color: #aaa;
  justify-content: space-between;
}
.optTime {
  margin-right: 50px;
}
.img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.disc {
  width: 500px;
  margin-left: 50px;
  font-size: 12px;
  padding-right: 10px;
  box-sizing: border-box;
}
.SID{
  color:#666;
}
.popoverSty{
  line-height: 20px;
  color:#888;
}
.noDataText{
   padding-top:20px;
  letter-spacing: 1px;
  padding-bottom: 30px;
}
</style>
